exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-studies-allegis-js": () => import("./../../../src/pages/case-studies/allegis.js" /* webpackChunkName: "component---src-pages-case-studies-allegis-js" */),
  "component---src-pages-case-studies-ammamia-js": () => import("./../../../src/pages/case-studies/ammamia.js" /* webpackChunkName: "component---src-pages-case-studies-ammamia-js" */),
  "component---src-pages-case-studies-coachforce-js": () => import("./../../../src/pages/case-studies/coachforce.js" /* webpackChunkName: "component---src-pages-case-studies-coachforce-js" */),
  "component---src-pages-case-studies-cockpit-js": () => import("./../../../src/pages/case-studies/cockpit.js" /* webpackChunkName: "component---src-pages-case-studies-cockpit-js" */),
  "component---src-pages-case-studies-delhivery-js": () => import("./../../../src/pages/case-studies/delhivery.js" /* webpackChunkName: "component---src-pages-case-studies-delhivery-js" */),
  "component---src-pages-case-studies-digitalpayment-js": () => import("./../../../src/pages/case-studies/digitalpayment.js" /* webpackChunkName: "component---src-pages-case-studies-digitalpayment-js" */),
  "component---src-pages-case-studies-dynamind-js": () => import("./../../../src/pages/case-studies/dynamind.js" /* webpackChunkName: "component---src-pages-case-studies-dynamind-js" */),
  "component---src-pages-case-studies-e-2-log-js": () => import("./../../../src/pages/case-studies/e2log.js" /* webpackChunkName: "component---src-pages-case-studies-e-2-log-js" */),
  "component---src-pages-case-studies-ednectar-js": () => import("./../../../src/pages/case-studies/ednectar.js" /* webpackChunkName: "component---src-pages-case-studies-ednectar-js" */),
  "component---src-pages-case-studies-ekam-sikh-js": () => import("./../../../src/pages/case-studies/ekam_sikh.js" /* webpackChunkName: "component---src-pages-case-studies-ekam-sikh-js" */),
  "component---src-pages-case-studies-fractal-js": () => import("./../../../src/pages/case-studies/fractal.js" /* webpackChunkName: "component---src-pages-case-studies-fractal-js" */),
  "component---src-pages-case-studies-hegde-hospital-js": () => import("./../../../src/pages/case-studies/hegde-hospital.js" /* webpackChunkName: "component---src-pages-case-studies-hegde-hospital-js" */),
  "component---src-pages-case-studies-hgc-js": () => import("./../../../src/pages/case-studies/hgc.js" /* webpackChunkName: "component---src-pages-case-studies-hgc-js" */),
  "component---src-pages-case-studies-inspirage-js": () => import("./../../../src/pages/case-studies/inspirage.js" /* webpackChunkName: "component---src-pages-case-studies-inspirage-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-case-studies-lyca-js": () => import("./../../../src/pages/case-studies/lyca.js" /* webpackChunkName: "component---src-pages-case-studies-lyca-js" */),
  "component---src-pages-case-studies-mobility-js": () => import("./../../../src/pages/case-studies/mobility.js" /* webpackChunkName: "component---src-pages-case-studies-mobility-js" */),
  "component---src-pages-case-studies-msig-js": () => import("./../../../src/pages/case-studies/Msig.js" /* webpackChunkName: "component---src-pages-case-studies-msig-js" */),
  "component---src-pages-case-studies-permasol-js": () => import("./../../../src/pages/case-studies/permasol.js" /* webpackChunkName: "component---src-pages-case-studies-permasol-js" */),
  "component---src-pages-case-studies-pharma-js": () => import("./../../../src/pages/case-studies/pharma.js" /* webpackChunkName: "component---src-pages-case-studies-pharma-js" */),
  "component---src-pages-case-studies-quickhealcc-js": () => import("./../../../src/pages/case-studies/quickhealcc.js" /* webpackChunkName: "component---src-pages-case-studies-quickhealcc-js" */),
  "component---src-pages-case-studies-quickhealccms-js": () => import("./../../../src/pages/case-studies/quickhealccms.js" /* webpackChunkName: "component---src-pages-case-studies-quickhealccms-js" */),
  "component---src-pages-case-studies-quickhealcrw-js": () => import("./../../../src/pages/case-studies/quickhealcrw.js" /* webpackChunkName: "component---src-pages-case-studies-quickhealcrw-js" */),
  "component---src-pages-case-studies-ramen-js": () => import("./../../../src/pages/case-studies/ramen.js" /* webpackChunkName: "component---src-pages-case-studies-ramen-js" */),
  "component---src-pages-case-studies-seventhsense-js": () => import("./../../../src/pages/case-studies/seventhsense.js" /* webpackChunkName: "component---src-pages-case-studies-seventhsense-js" */),
  "component---src-pages-case-studies-singer-js": () => import("./../../../src/pages/case-studies/singer.js" /* webpackChunkName: "component---src-pages-case-studies-singer-js" */),
  "component---src-pages-case-studies-sistic-js": () => import("./../../../src/pages/case-studies/sistic.js" /* webpackChunkName: "component---src-pages-case-studies-sistic-js" */),
  "component---src-pages-case-studies-stock-js": () => import("./../../../src/pages/case-studies/stock.js" /* webpackChunkName: "component---src-pages-case-studies-stock-js" */),
  "component---src-pages-case-studies-tdis-js": () => import("./../../../src/pages/case-studies/Tdis.js" /* webpackChunkName: "component---src-pages-case-studies-tdis-js" */),
  "component---src-pages-case-studies-trueweight-js": () => import("./../../../src/pages/case-studies/trueweight.js" /* webpackChunkName: "component---src-pages-case-studies-trueweight-js" */),
  "component---src-pages-case-studies-yoga-js": () => import("./../../../src/pages/case-studies/yoga.js" /* webpackChunkName: "component---src-pages-case-studies-yoga-js" */),
  "component---src-pages-case-study-details-js": () => import("./../../../src/pages/caseStudyDetails.js" /* webpackChunkName: "component---src-pages-case-study-details-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dynamind-cs-details-js": () => import("./../../../src/pages/dynamindCsDetails.js" /* webpackChunkName: "component---src-pages-dynamind-cs-details-js" */),
  "component---src-pages-home-page-js": () => import("./../../../src/pages/homePage.js" /* webpackChunkName: "component---src-pages-home-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-fintech-js": () => import("./../../../src/pages/industries/fintech.js" /* webpackChunkName: "component---src-pages-industries-fintech-js" */),
  "component---src-pages-industries-healthcare-js": () => import("./../../../src/pages/industries/healthcare.js" /* webpackChunkName: "component---src-pages-industries-healthcare-js" */),
  "component---src-pages-industries-insurance-js": () => import("./../../../src/pages/industries/insurance.js" /* webpackChunkName: "component---src-pages-industries-insurance-js" */),
  "component---src-pages-industries-logistic-js": () => import("./../../../src/pages/industries/logistic.js" /* webpackChunkName: "component---src-pages-industries-logistic-js" */),
  "component---src-pages-industries-solutions-js": () => import("./../../../src/pages/industries/solutions.js" /* webpackChunkName: "component---src-pages-industries-solutions-js" */),
  "component---src-pages-industries-telecommunication-js": () => import("./../../../src/pages/industries/telecommunication.js" /* webpackChunkName: "component---src-pages-industries-telecommunication-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-oautomate-js": () => import("./../../../src/pages/products/oautomate.js" /* webpackChunkName: "component---src-pages-products-oautomate-js" */),
  "component---src-pages-products-thrymros-js": () => import("./../../../src/pages/products/thrymros.js" /* webpackChunkName: "component---src-pages-products-thrymros-js" */),
  "component---src-pages-products-workflow-js": () => import("./../../../src/pages/products/workflow.js" /* webpackChunkName: "component---src-pages-products-workflow-js" */),
  "component---src-pages-rpa-page-js": () => import("./../../../src/pages/rpaPage.js" /* webpackChunkName: "component---src-pages-rpa-page-js" */),
  "component---src-pages-services-ai-ml-js": () => import("./../../../src/pages/services/ai-ml.js" /* webpackChunkName: "component---src-pages-services-ai-ml-js" */),
  "component---src-pages-services-api-development-js": () => import("./../../../src/pages/services/api-development.js" /* webpackChunkName: "component---src-pages-services-api-development-js" */),
  "component---src-pages-services-block-chain-js": () => import("./../../../src/pages/services/block-chain.js" /* webpackChunkName: "component---src-pages-services-block-chain-js" */),
  "component---src-pages-services-cloud-computing-js": () => import("./../../../src/pages/services/cloud-computing.js" /* webpackChunkName: "component---src-pages-services-cloud-computing-js" */),
  "component---src-pages-services-data-analysis-js": () => import("./../../../src/pages/services/data-analysis.js" /* webpackChunkName: "component---src-pages-services-data-analysis-js" */),
  "component---src-pages-services-data-engineering-js": () => import("./../../../src/pages/services/data-engineering.js" /* webpackChunkName: "component---src-pages-services-data-engineering-js" */),
  "component---src-pages-services-development-js": () => import("./../../../src/pages/services/development.js" /* webpackChunkName: "component---src-pages-services-development-js" */),
  "component---src-pages-services-development-teams-js": () => import("./../../../src/pages/services/development-teams.js" /* webpackChunkName: "component---src-pages-services-development-teams-js" */),
  "component---src-pages-services-frontend-development-js": () => import("./../../../src/pages/services/frontend-development.js" /* webpackChunkName: "component---src-pages-services-frontend-development-js" */),
  "component---src-pages-services-managed-services-js": () => import("./../../../src/pages/services/managed-services.js" /* webpackChunkName: "component---src-pages-services-managed-services-js" */),
  "component---src-pages-services-mobile-applications-js": () => import("./../../../src/pages/services/mobile-applications.js" /* webpackChunkName: "component---src-pages-services-mobile-applications-js" */),
  "component---src-pages-services-quality-assurance-js": () => import("./../../../src/pages/services/quality-assurance.js" /* webpackChunkName: "component---src-pages-services-quality-assurance-js" */),
  "component---src-pages-services-test-automation-js": () => import("./../../../src/pages/services/test-automation.js" /* webpackChunkName: "component---src-pages-services-test-automation-js" */),
  "component---src-pages-services-trained-resourcing-services-js": () => import("./../../../src/pages/services/trained-resourcing-services.js" /* webpackChunkName: "component---src-pages-services-trained-resourcing-services-js" */),
  "component---src-pages-services-ui-ux-services-js": () => import("./../../../src/pages/services/ui-ux-services.js" /* webpackChunkName: "component---src-pages-services-ui-ux-services-js" */),
  "component---src-pages-services-web-applications-js": () => import("./../../../src/pages/services/web-applications.js" /* webpackChunkName: "component---src-pages-services-web-applications-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

